import React from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import Header from "@shared/components/Header";
import ContactForm from "@shared/components/ContactForm";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`p-5`}
`;
const Title = styled.div`
  ${tw`text-2xl text-gray-700 my-5 text-center sm:text-start`}
`;
const MapContainer = styled.div`
  ${tw`mt-4`}
`;
const InfoContainer = styled.div`
  ${tw`
    grid sm:grid-cols-2 lg:grid-cols-3
    mt-10 gap-5
  `}
`;
const Info = styled.div`
  ${tw`md:text-lg text-center`}
`;
const InfoTitle = styled.span`
  ${tw`font-semibold text-[#EC1F27]`}
`;
const InfoAnchor = styled.a`
  ${tw`hover:underline`}
`;

const ContactPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, hero, tabs, staff },
    },
  },
  location,
}) => {
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <MapContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2526.0395308797447!2d-113.95076894851196!3d50.71920577941281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537199d9ad1860b3%3A0x2a8c442d69a0c362!2sSeaman%20Stadium!5e0!3m2!1sen!2sca!4v1681150379618!5m2!1sen!2sca"
            height="450"
            style={{ border: 0, width: "100%" }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </MapContainer>
        <Title>Contact Form</Title>
        <ContactForm color={MAIN_COLOR} />
        <InfoContainer>
          <Info>
            <InfoTitle>Email:</InfoTitle>{" "}
            <InfoAnchor href="mailto:info@dawgsbaseball.ca">
              info@dawgsbaseball.ca
            </InfoAnchor>
          </Info>
          <Info>
            <InfoTitle>Telephone:</InfoTitle>{" "}
            <InfoAnchor href="tel:14032623294">
              (403) 262-DAWG (3294)
            </InfoAnchor>
          </Info>
          <Info tw="sm:col-span-2 lg:col-span-1">
            <InfoTitle>Address:</InfoTitle> 256 Don Seaman Way, Okotoks, AB T1S
            1A5
          </Info>
        </InfoContainer>
      </Container>
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        staff {
          name
          image {
            childImageSharp {
              gatsbyImageData(height: 250, aspectRatio: 1)
            }
          }
          position
          email
        }
      }
    }
  }
`;
